<template>
  <section
    class="results-count-details"
  >
    <h5
      data-testid="resultsCountHeading"
    >
      {{ heading }}
    </h5>
    <div data-testid="resultsCountValue">
      {{ count }} results
    </div>
  </section>
</template>

<script>
export default {
  name: 'ResultsCount',
  props: {
    heading: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .results-count-details {
    border-top: 1pt solid black;
    border-bottom: 1pt solid black;
    padding: 20px 0;

    h5 {
      margin-bottom: 15px;
    }
  }

</style>
