<template>
  <div class="result-block-secondary">
    <b-row no-gutter>
      <b-col>
        <h2 data-testid="resultBlockTitle">
          <slot name="heading" />
        </h2>
      </b-col>
    </b-row>
    <b-row
      no-gutter
      class="row-pad"
    >
      <b-col
        offset-lg="3"
        offset-md="0"
      >
        <sort-by-dropdown
          :default-selected="defaultSelected"
          class="d-inline-block"
          @sortBy="sortBy($event)"
        />
        <div
          class="result-count d-md-inline-block d-lg-none"
          data-testid="resultBlockCount"
        >
          {{ resultCountFormated }} results
        </div>
        <pagination-custom
          :current-page="currentPage"
          :total-pages="pageCount"
          class="float-right d-sm-none d-md-inline-block"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol
} from 'bootstrap-vue'
import SortByDropdown from '../organisms/SortByDropdown'
import paginationCustom from '../molecules/PaginationCustom'
import { numberToLocaleString } from '../../utils/FormatData'
export default {
  name: 'ResultBlockSecondary',
  components: {
    BRow,
    BCol,
    SortByDropdown,
    paginationCustom
  },
  props: {
    defaultSelected: {
      type: String,
      required: false,
      default: null
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    resultCount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      numberOfItemsPerPage: 10
    }
  },
  computed: {
    resultCountFormated () {
      return numberToLocaleString(this.resultCount)
    },
    pageCount () {
      return Math.round(this.resultCount / this.numberOfItemsPerPage)
    }
  },
  methods: {
    sortBy (value) {
      const sortingType = value || this.defaultSelected
      this.$emit('sortBy', sortingType)
    }
  }
}
</script>
<style scoped lang="scss">
.result-block-secondary {
  h2 {
    @extend %typography-h2;

    span {
      font-weight: 400;
    }
  }

  .result-count {
    @extend %typography-b2;

    padding-left: 25px;
    line-height: 40px;
  }

  .grey-line {
    border-bottom: 1pt solid #e5e5e5;
  }

  .row-pad {
    padding-top: 24px;
    padding-bottom: 16px;
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .result-block-secondary {
    .row-pad {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .result-block-secondary {
    .row-pad {
      padding-top: 24px;
      padding-bottom: 16px;
    }

    .result-count {
      float: right;
    }
  }
}
</style>
