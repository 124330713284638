<template>
  <book-category-template :primary-data="primaryData" />
</template>

<script>
import BookCategoryTemplate from '../components/templates/BookCategoryTemplate'
// bookCategoryDetailState should come from Vuex state while intergate with API
// Below import is temperary one and added for dev purpose
import bookCategoryData from '../../mocks/bookCategoryDetail.json'
export default {
  name: 'BookCategoryPage',
  components: {
    BookCategoryTemplate
  },
  data: function () {
    return {
      primaryData: bookCategoryData.data
    }
  }

}
</script>
