<template>
  <article class="book-category">
    <b-container>
      <b-row>
        <b-col>
          <breadcrumbs
            :items="primaryData.breadcrumbs"
            :article-title="primaryData.mainItem.title"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="header-block-wraper">
          <header-block
            :type="primaryData.mainItem.mainType"
            :title="primaryData.mainItem.title"
            :description="primaryData.mainItem.description"
            :background-image-url="primaryData.mainItem.image.src"
            :without-c-t-a="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="result-block-wraper">
          <result-block-secondary
            :result-count="primaryData.resultDetails.resultCount"
            :current-page="1"
            default-selected="newest"
          >
            <template #heading>
              {{ primaryData.mainItem.title }} <span>Books</span>
            </template>
          </result-block-secondary>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="3"
          sm="12"
          class="d-none d-lg-block d-xl-none"
        >
          <section class="filter-block-wraper">
            <results-count
              heading="Results"
              :count="primaryData.resultDetails.resultCount"
            />
          </section>
        </b-col>
        <b-col
          lg="9"
          sm="12"
          class="result-list-wraper"
        >
          <book-preview
            v-for="(book, i) of primaryData.books"
            :key="`bookList${i}`"
            :title="book.title"
            :category-name="book.categoryName"
            :updated-at="book.updatedAt"
            :description="book.description"
            :image-src="book.imageSrc"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="bottom-pagination-wraper">
          <pagination-custom
            :current-page="1"
            :total-pages="2"
            direction="top"
            class="float-right"
          />
        </b-col>
      </b-row>
    </b-container>
  </article>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import Breadcrumbs from '../molecules/Breadcrumbs'
import HeaderBlock from '../organisms/HeaderBlock'
import ResultBlockSecondary from '../../components/organisms/ResultBlockSecondary'
import BookPreview from '../../components/organisms/BookPreview'
import ResultsCount from '../../components/atoms/ResultsCount'
import paginationCustom from '../molecules/PaginationCustom'

export default {
  name: 'BookCategoryTemplate',
  components: {
    BContainer,
    BRow,
    BCol,
    Breadcrumbs,
    HeaderBlock,
    ResultBlockSecondary,
    BookPreview,
    ResultsCount,
    paginationCustom
  },
  props: {
    primaryData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.book-category {
  >.container {
    padding: 30px;
    max-width: $pg-max-width;
    height: inherit;

    .header-block-wraper {
      padding-top: 48px;
    }

    .result-block-wraper {
      padding-top: 40px;
      padding-bottom: 4px;
    }

    .filter-block-wraper {
      padding-right: 16px;
    }

    .bottom-pagination-wraper {
      padding-top: 16px;
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .book-category {
    >.container {
      padding: 18px;
      max-width: $pg-max-width;
      height: inherit;

      .header-block-wraper {
        padding-top: 35px;

        /deep/ {
          .header-block {
            border-bottom: 1px solid $black;
          }
        }
      }

      .result-block-wraper {
        padding-top: 35px;
        padding-bottom: 0;
      }

      .filter-block-wraper {
        padding-right: 0;

        /deep/ {
          .filter-btn {
            display: none;
          }
        }
      }

      .result-list-wraper {
        /deep/ {
          .content-preview-row {
            &:first-child {
              .content-preview {
                border-top: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .book-category {
    >.container {
      .result-block-wraper {
        padding-bottom: 0;
      }

      .filter-block-wraper {
        margin-top: -20px;
      }
    }
  }
}
</style>
