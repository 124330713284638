<template>
  <b-row class="book-preview">
    <b-col>
      <div class="inner grey-border">
        <div class="image">
          <img
            :src="imageSrc"
            data-testid="bookPreviewImage"
            loading="lazy"
          >
        </div>
        <div class="content">
          <router-link
            to="/"
            data-testid="bookPreviewCategoryLink"
          >
            <h3 class="b1 category-name">
              {{ categoryName }}
            </h3>
          </router-link>
          <router-link
            to="/"
            data-testid="bookPreviewTitleLink"
          >
            <h3 class="h3 title">
              {{ title }}
            </h3>
          </router-link>
          <p
            class="b1 date"
            data-testid="bookPreviewPublishedDate"
          >
            Published {{ formattedDate }}
          </p>
          <p
            class="b1 description"
            data-testid="bookPreviewDescription"
          >
            {{ description }}
          </p>
          <router-link
            to="/"
            data-testid="bookPreviewExploreLink"
          >
            <h3 class="b1 explore">
              Explore Book
            </h3>
          </router-link>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { dateStringTommddyyyy, dateStringValidator } from '../../utils/DateTime'
export default {
  name: 'BookPreview',
  components: { BRow, BCol },
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    categoryName: {
      type: String,
      required: true
    },
    updatedAt: {
      type: String,
      required: true,
      validator: dateStringValidator
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedDate: function () {
      return dateStringTommddyyyy(this.updatedAt)
    }
  }
}
</script>

<style lang="scss" scoped>

.book-preview {
  .category-name {
    @extend %text-underline;

    font-weight: 600;
    color: $red-1;
    margin-bottom: 14px;
  }

  .explore {
    @extend %text-color-change;

    font-weight: 600;
    text-decoration: underline;
  }

  .inner {
    padding-top: 35px;
    padding-bottom: 35px;
    display: flex;
    overflow: hidden;
  }

  &:last-child {
    .inner {
      border-bottom: 1pt solid $grey-6;
    }
  }

  .title {
    @extend %text-underline;

    margin-bottom: 14px;
    text-align: left;
  }

  .date {
    font-style: italic;
    color: $grey-3;
    margin-bottom: 14px;
  }

  .description {
    margin-bottom: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    &::after {
      content: '...';
    }
  }

  .image {
    float: left;

    img {
      width: 144px;
      height: 180px;
      max-height: 100%;
    }
  }

  .content {
    padding-left: 32px;
    float: left;
  }

  .grey-border {
    @extend %grey-border;
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .book-preview {
    .image {
      img {
        width: 120px;
        height: 150px;
      }
    }

    .content {
      padding-left: 24px;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .book-preview {
    .image {
      img {
        width: 100px;
        height: 125px;
      }
    }
  }
}
</style>
